import React from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import CustomLink from '@components/global/CustomLink';
import { Button } from '@components/ui/Button';

import { addPageInfoToSerachParams } from '@helpers/utils';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './Bottom.module.css';

const Content = () => {
    const { t } = useTranslation('errors/error404');
    const { locale } = useRouter();
    const { pageProperties } = useAppContext();
    return (
        <div className={styles.bottom}>
            <div className={styles.btnWrap}>
                <CustomLink href={{ pathname: '/features' }}>
                    <Button
                        color="blue"
                        label={t('moreFeatures')}
                    />
                </CustomLink>
                {/* <a href={laravelRouteFeatures(locale)}>
                    <Button
                        color="blue"
                        label={t('moreFeatures')}
                    />
                </a> */}
                <a
                    href={addPageInfoToSerachParams(pageProperties, laravelRouteStartFree(locale))}
                    className={styles.link}
                >
                    {t('startFreeTrial')} ›
                </a>
            </div>
        </div>
    );
};

export default function Bottom({ container, className }) {
    return (
        <section className={clsx(styles.intro, className)}>
            {React.cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}

Bottom.propTypes = {
    container: PropTypes.object.isRequired,
    className: PropTypes.string,
};
