import React from 'react';

import Trans from '@components/Trans';
import Mark from '@components/ui/Mark';

import SVGHero404 from '@public/pages/404/hero.svg';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './Hero.module.css';

const Content = () => {
    const { t } = useTranslation('errors/error404');
    return (
        <div className={styles.heading}>
            <div className={styles.image}>
                <img
                    src={SVGHero404.src}
                    width="600"
                    height="480"
                    alt="404"
                />
            </div>
            <div className={styles.description}>
                <h1>
                    <Trans
                        i18nKey="apologiesNEXT"
                        components={{
                            mark: <Mark />,
                        }}
                        ns="errors/error404"
                    />
                </h1>
                <p dangerouslySetInnerHTML={{ __html: t('errorDescription') }}></p>
                <p dangerouslySetInnerHTML={{ __html: t('errorAlternate') }}></p>
            </div>
        </div>
    );
};

export default function Hero({ container, className }) {
    return (
        <section className={clsx(styles.intro, className)}>
            {React.cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}

Hero.propTypes = {
    container: PropTypes.object.isRequired,
    className: PropTypes.string,
};
